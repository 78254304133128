.change-password {
    padding: 30px;
    @media screen and (min-width: 1200px){
        padding-left: 350px;
        width: 60%;
    }
}

.enabled-button {
    width: 500px;
    color: white;
    background-color: rgb(3, 128, 252);
    height: 45px;
    font-size: 18px
}

.disabled-button {
    width: 500px;
    color: white;
    background-color: rgb(61, 58, 58);
    height: 45px;
    font-size: 18px
}