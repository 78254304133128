.prompt {
    &-wrapper {
        position: absolute;
        top: 0px;
        left: 0;
        display: flex;
        height: 150%;
        width: 100%;
        justify-content: center;
        background: rgba(0, 0, 0, 0.41);

    }
    &-container {
        max-height: 700px;
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        background: white;
        max-height: 50vh;
        overflow-y: auto;
        padding: 35px;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        position: relative;
        .icon-container {
            margin-top: 30px;
            margin-bottom: 20px;
            .icon-warning {
                width: 44px;
                height: 38px;
                fill: #FCBF4A;
            }
            .icon-success {
                font-size: 36px;
                color: #32E279;
            }
            .icon-info {
                font-size: 36px;
                color: #0201F1;
            }
        }
        .heading {
            font-size: 24px;
            font-weight: 600;
            line-height: 24px;
            margin-bottom: 22px;
        }
        .note {
            text-align: center;
            font-weight: 400;
            margin-bottom: 55px;
        }
    }
}