@import '../../../public//styles/utils/variables';
@import '../../../public//styles/utils/mixins';

.text-box-label {
    margin-bottom: 5px !important;
    color: grey;
}

.ant-input {
    @media screen and (max-width: 768px)  {
        height: 20px;
        font-size: 12px;
    }
    height: 30px;
    margin-top:25px;
    font-size: 14px;
    width: 98%;
    padding: 4px;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid grey;
    outline: none !important;
    border-radius: 0px;
}

.ant-input::placeholder {
    color: #999999;
}

.ant-input:hover {
    @media screen and (max-width: 768px)  {
        height: 20px;
        font-size: 12px;
    }
    height: 30px;
    margin-top:25px;
    font-size: 14px;
    width: 98%;
    padding: 4px;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid grey;
    outline: none;
}

.ant-input:not(:hover) {
    @media screen and (max-width: 768px)  {
        height: 20px;
        font-size: 12px;
    }
    height: 30px;
    margin-top:25px;
    font-size: 14px;
    width: 98%;
    padding: 4px;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid grey;
    outline: none;
}

.ant-input:focus {
    box-shadow: none !important;
    outline-style: none !important;
    border-color: #ECECF4;

}

.ant-input-status-error:hover {
    border-color: #ff4d4f !important;
}


.input-mask {
    padding: 5px;
    border-style: solid;
    border-width: 1px;
    border-color: #ECECF4;
    background-color: white;    
}

.input-mask::placeholder{
    color: #999999;
}

.input-mask:hover{
    border-style: solid;
    border-width: 1px;
    border-color: #ECECF4 !important;
    background-color: white;
}

.input-mask:not(:hover){
    border-style: solid;
border-width: 1px;
background-color: white;
}

.input-mask:focus{
    box-shadow: none !important;
    outline-style: none !important;
    border-color: #ECECF4;
}

.input-mask-error{
    border-color: #ff4d4f !important;
}

.input-mask-error:hover{
    border-color: #ff4d4f !important;
}

.row-flex {
    display: flex;
    flex-direction: row;
}

.anticon[tabindex] {
    padding-top: 35px;
    border-bottom: 1px solid grey;
    font-size: x-large;
}