.logout-button  {
    background-color: rgb(3, 128, 252);
    color: aliceblue;
    width: 100px;
    height: 50px;
    font-size: 18px;
    font-weight: 700;
    cursor: pointer;
}

.logo {
    width: 50px;
    @media screen and (max-width: 768px) {
        width: 35px;
    }
}