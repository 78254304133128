.ant-form-item-control-input {
    min-height: 50px !important;
}

.input-style {
    @media screen and (max-width: 768px)  {
        height: 20px;
        font-size: 12px;
    }
    height: 30px;
    margin-top:25px;
    font-size: 14px;
    width: 98%;
    padding: 4px;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid grey;
    outline: none;
}

.grey {
    color: grey;
}

.form-wrapper {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 32px;
    background: 'linear-gradient(to right, #4e73df, #1e3799)';

    .left {
        .input-wrapper, .dropdown-wrapper {
            margin-bottom: 15px;
            font-size: '24px';
            display: 'flex';
            justify-content: 'center';
            align-items: 'center';
            height: '100%'
        }
    }
}

.login-button  {
    background-color: rgb(3, 128, 252);
    color: aliceblue;
    width: 78%;
    height: 50px;
    font-size: 20px;
    font-weight: 700;
    margin-top: 10px;
}

.center {
    display: 'flex';
    flex-direction: 'column';
    justify-content: 'center';
    align-items: 'center'
}

.transparent-underline-input {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid grey;
    outline: none;
}