.ant-form-item-control-input {
    min-height: 50px !important;
}

.input-style {
    height: 50px;
    font-size: 18px;
}

.login-button {
    width: 100%;
    height: 50px;
    font-size: 20px;
    cursor: pointer;
}

.label {
    color: grey;
}

.grey {
    color: grey;
}

.form-wrapper {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 32px;
    padding: '20px';
    color: '#fff';

    .left {
        .input-wrapper, .dropdown-wrapper {
            margin-bottom: 15px;
            font-size: '24px'; // Adjust the font size as needed
            display: 'flex';
            justify-content: 'center';
            align-items: 'center';
            height: '100%'
        }
    }
}

.ant-select-selection-search-input {
    @media screen and (max-width: 768px)  {
        height: 20px;
        font-size: 12px;
    }
    height: 30px;
    margin-top:25px;
    font-size: 14px;
    width: 98%;
    padding: 4px;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid grey;
    outline: none !important;
    border-radius: 0px;
}